import { Header } from "./Header";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { EnterButton } from "./EnterButton";

export const About = () => {
  return (
    <div className="App text-gray-800">
      <Header showLogoutButton={false} showEnterButton />
      <div className="flex flex-col sm:flex-row p-4 h-full">
        <div className="flex-1 text-left">
          <h3 className="text-3xl mt-4">
            Learn songs the smart way, completely free
          </h3>
          <p className="mt-4">
            Do you have a song that you want to learn? On this website, you can
            add any youtube song, and the website will split the voice from the
            music, and you can then choose to play the song with or without the
            voice. You can also change the pitch to fit to your voice and change
            the speed to fit your learning progress. Another super helpful
            functionality is that you can select specific sections in the song
            to loop, so you don't have to manually go to the specific part every
            time.
            <br />
            <br />
            A big thanks to Demucs, which is the state of the art machine
            learning model which separates the voice from the music and
            AmplitudeJS which is the library used for playing the music in the
            browser.
            <br />
            <br />
            For any queries, feel free to email us at hello@singipedia.net
          </p>
          <div className="sm:hidden">
            <br />
            <p>
              Check the slideshow below to see some screenshots of the service
            </p>
          </div>
          <EnterButton />
        </div>
        <div className="flex-1 border-4 border-white bg-white pb-8">
          <Carousel autoPlay dynamicHeight>
            <div>
              <img src="add-song.png" alt="" />
              <p className="legend">Add any song from youtube</p>
            </div>
            <div>
              <img src="library.png" alt="" />
              <p className="legend">The library of existing songs</p>
            </div>
            <div>
              <img src="audioplayer.png" alt="" />
              <p className="legend">
                When a song is selected, you can choose to enable or disable the
                voice, change pitch, speed and loop specific sections
              </p>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

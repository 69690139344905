import { FaButton } from "./FaButton";

type Props = {
  onPlus: () => void;
  onMinus: () => void;
  repeatOnHolding: boolean;
};

export const PlusMinus = ({ onPlus, onMinus, repeatOnHolding }: Props) => {
  return (
    <div>
      <FaButton
        className="text-[#999]"
        repeatOnHolding={repeatOnHolding}
        onClick={onMinus}
        icon="minus-square"
      />
      <FaButton
        className="text-[#999]"
        repeatOnHolding={repeatOnHolding}
        onClick={onPlus}
        icon="plus-square"
      />
    </div>
  );
};

PlusMinus.defaultProps = {
  repeatOnHolding: false,
};

import React, { useContext, useRef, useState } from "react";
import { PlayButton } from "../AudioPlayer/PlayButton";
import { GlobalsContext } from "../App";
import { FaButton } from "../AudioPlayer/FaButton";
import Swal from "sweetalert2";
import { ErrorWrapper, utils } from "../utils/utils";

export enum SongStatus {
  "Requested" = "Requested",
  "Processing" = "Processing",
  "Succeeded" = "Succeeded",
  "Failed" = "Failed",
  "Queued" = "Queued",
}

export type Song = {
  id: string;
  status: SongStatus;
  smallestThumbnailUrl: string;
  title: string;
  durationInSeconds: number;
  requestedBy: string;
  lyrics: string | null;
};

export type SongWithUrls = Song & {
  urls: {
    pitchMinus6: string;
    pitchMinus5: string;
    pitchMinus4: string;
    pitchMinus3: string;
    pitchMinus2: string;
    pitchMinus1: string;
    originalPitch: string;
    pitchPlus1: string;
    pitchPlus2: string;
    pitchPlus3: string;
    pitchPlus4: string;
    pitchPlus5: string;
    pitchPlus6: string;
  };
};

type Props = {
  songs: Song[];
  onSelectSong: (songId: string) => Promise<unknown>;
  acceptSong: (youtubeId: string) => unknown;
  favoriteSongIds: string[];
  requestSong: (youtubeUrl: string) => Promise<ErrorWrapper | { id: string }>;
  currentSongId: string | null;
};

const truncateText = (text: string, length: number): string => {
  if (text.length <= length) {
    return text;
  }
  return text.substring(0, length) + "...";
};

const text = {
  [SongStatus.Failed]: "Failed",
  [SongStatus.Processing]: "Processing - This normally takes around 20 minutes",
  [SongStatus.Requested]:
    "Requested - The page admin needs to accept this song",
  [SongStatus.Queued]:
    "Queued - The song has been accepted and will be processed when the machine is available",
};

const removeNonAlphanumeric = (inputString: string): string =>
  inputString.replace(/[^a-zA-Z0-9]/g, "");

export const Library: React.FC<Props> = ({
  songs,
  onSelectSong,
  acceptSong,
  favoriteSongIds,
  requestSong,
  currentSongId,
}) => {
  const { user } = useContext(GlobalsContext);
  const [query, setQuery] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const onSearchInputFocus = () => {
    inputRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const isFavorite = (songId: string) => favoriteSongIds.includes(songId);
  const filteredAndSortedSongs = songs
    .filter(
      (song) =>
        ["Succeeded", "Processing", "Queued"].includes(song.status) ||
        ((user.isAdmin === true || song.requestedBy === user.id) &&
          song.status === "Requested")
    )
    .filter((song) =>
      removeNonAlphanumeric(song.title.toLowerCase()).includes(
        removeNonAlphanumeric(query.toLowerCase())
      )
    )
    .sort((a, b) => {
      const statusOrder = {
        Requested: 1,
        Queued: 2,
        Processing: 3,
        Failed: 4,
        Succeeded: 5,
      };
      const statusComparison = statusOrder[a.status] - statusOrder[b.status];

      if (statusComparison !== 0) {
        return statusComparison;
      }

      // If status is the same, compare isFavorite (true first)
      if (isFavorite(a.id) !== isFavorite(b.id)) {
        return isFavorite(a.id) ? -1 : 1;
      }

      // If isFavorite is the same, compare titles alphabetically
      return a.title.localeCompare(b.title);
    });

  return (
    <div className="sm:px-[2em] mt-2 sm:w-[40em] mb-[8em]">
      <div className="white-card pb-2 mb-4 sm:rounded min-h-[50em]">
        <button
          onClick={async () => {
            const { isConfirmed, value } = await Swal.fire({
              heightAuto: false,
              title: "Add song from YouTube",
              reverseButtons: true,
              text: "You can add a song from youtube if you can't find it in the library. After adding it, it normally takes around 20 minutes before it's available in the library due to processing. It will automatically be favorited for you, and you will get a mail once it is finished",
              input: "text",
              showCancelButton: true,
              confirmButtonText: "Add",
              inputPlaceholder: "Enter YouTube link",
            });
            if (isConfirmed === true && value !== undefined) {
              const requestSongResponse = await requestSong(value);
              if (utils.isErrorWrapper(requestSongResponse)) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: requestSongResponse.error,
                });
              }
            }
          }}
          className="text-[#414344] hover:text-gray-500 p-2 w-[9em] absolute right-0 text-blue-800"
        >
          + Add song
        </button>
        <h3 className="now-playing pt-6 text-xl">Song library</h3>
        <div className="mt-4 w-full flex p-2">
          <input
            ref={inputRef}
            onFocus={onSearchInputFocus}
            type="text"
            onChange={(e) => setQuery(e.target.value)}
            className="border p-2 mr-2 w-full rounded"
            placeholder="Search songs"
          />
        </div>
        <ul className="mt-4 border-b">
          {filteredAndSortedSongs.map((song) => (
            <li
              key={song.id}
              className={`flex p-2 border-t w-full ${
                currentSongId === song.id ? "bg-gray-200" : ""
              } ${
                song.status === "Succeeded"
                  ? "cursor-pointer hover:bg-gray-100"
                  : ""
              }`}
              onClick={async () => {
                if (song.status === "Succeeded") {
                  await onSelectSong(song.id);
                  setTimeout(() => {
                    document.getElementById("header")?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }, 500);
                }
              }}
            >
              <div className="flex flex-row justify-between w-full items-center">
                <div className="w-[2em] h-[2em] relative">
                  {song.status === "Requested" && user.isAdmin && (
                    <FaButton
                      onClick={() => acceptSong(song.id)}
                      size="lg"
                      icon="check"
                      className="text-yellow-700 sm:hover:text-green-700"
                    />
                  )}
                  {song.status === "Requested" &&
                    user.id === song.requestedBy &&
                    user.isAdmin === false && (
                      <i className="absolute left-[.43em] top-[.8em] fa-lg fa-solid fa-hourglass-empty" />
                    )}
                  {song.status === "Queued" && (
                    <i className="absolute left-[.43em] top-[.8em] fa-lg fa-solid fa-hourglass-empty" />
                  )}
                  {song.status === "Processing" && (
                    <span className="absolute left-[.43em] top-[.8em] fa-lg fa-solid fa-hourglass-start fa-bounce" />
                  )}
                  {song.status === "Succeeded" && (
                    <PlayButton size="small" onClick={() => {}} />
                  )}
                </div>
                <div className="pl-4 flex-1 w-full text-left w-full">
                  {song.status !== "Succeeded" && (
                    <p className="text-yellow-500">{text[song.status]}</p>
                  )}
                  <p>
                    {truncateText(`${song.title}`, 70)}{" "}
                    {isFavorite(song.id) && (
                      <i className="fa fa-heart text-red-600" />
                    )}
                  </p>
                </div>
                <img
                  width="80px"
                  src={song.smallestThumbnailUrl}
                  alt={song.title}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import "./audioplayer.css";
import axios from "axios";
import { BounceLoader } from "react-spinners";
import { Wrapper } from "./Wrapper/Wrapper";
import { LoginScreen } from "./LoginScreen";
import { selectedIdentityProviderKey, websiteDomain } from "./constants";
import { utils } from "./utils/utils";
import { FaButton } from "./AudioPlayer/FaButton";
import { CognitoIdentityCredentialProvider } from "@aws-sdk/credential-provider-cognito-identity";
import { awsUtils } from "./utils/awsUtils";
import { localStorageUtils } from "./utils/localStorageUtils";
import { version } from "./version";
import { About } from "./About";
import { Header } from "./Header";

export type AwsCredentials = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
};

export type Globals = {
  environmentVariables: EnvironmentVariables;
  user: User;
  credentialsProvider: CognitoIdentityCredentialProvider;
};

export type EnvironmentVariables = {
  region: string;
  cognito: {
    clientId: string;
    domain: string;
    userPoolId: string;
    identityPoolId: string;
  };
  bucketNames: {
    processed: string;
  };
  lambdaArns: {
    requestSong: string;
    acceptSong: string;
    saveConfiguration: string;
    deleteSong: string;
    addLyricsToSong: string;
  };
  tableNames: {
    configurations: string;
    favorites: string;
    songs: string;
  };
};

export type User = {
  id: string;
  email: string;
  isAdmin: boolean;
};

export type GetIdTokenResponse = {
  idToken: string;
  expiresInSeconds: number;
  user: User;
};

export const EnvironmentVariablesContext = createContext<EnvironmentVariables>(
  null!
);

export const GlobalsContext = createContext<Globals>(null!);

const removeCodeFromUrlWithoutReloading = (): void => {
  let url = new URL(window.location.href);
  url.searchParams.delete("code");
  window.history.replaceState({}, "", url.href);
};

const getCodeFromCurrentURL = (): string | null => {
  // Use window.location.search to get the query string part
  const queryParams = new URLSearchParams(window.location.search);
  // Get the 'code' parameter from the query string
  const code = queryParams.get("code");
  return code;
};

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [credentialsProvider, setCredentialsProvider] =
    useState<CognitoIdentityCredentialProvider | null>(null);
  const [environmentVariables, setEnvironmentVariables] =
    useState<EnvironmentVariables | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const init = async () => {
    const { data: environmentVariables } =
      await axios.get<EnvironmentVariables>("/api/environment-variables");
    console.log(environmentVariables);
    setEnvironmentVariables(environmentVariables);
    const authorizationCode = getCodeFromCurrentURL();
    if (authorizationCode) {
      const {
        data: { idToken, expiresInSeconds, user },
      } = await axios.get<GetIdTokenResponse>(`/api/get-id-token`, {
        params: { authorization_code: authorizationCode },
      });
      localStorageUtils.saveIdTokenResponseAndExpiration({
        ...{ idToken, expiresInSeconds, user },
        expiration: new Date().getTime() + expiresInSeconds * 1000,
      });
      removeCodeFromUrlWithoutReloading();
      setUser(user);
      setCredentialsProvider(
        await awsUtils.getCredentialsProvider(
          environmentVariables.region,
          environmentVariables.cognito.identityPoolId,
          environmentVariables.cognito.userPoolId,
          idToken
        )
      );
    } else if (localStorage.getItem(selectedIdentityProviderKey) !== null) {
      const getIdTokenResponseAndExpiration =
        localStorageUtils.getIdTokenResponseAndExpiration();
      if (
        getIdTokenResponseAndExpiration === null ||
        getIdTokenResponseAndExpiration.expiration - new Date().getTime() <
          1 * 60 * 60 * 1000
      ) {
        window.location.href = `${
          environmentVariables.cognito.domain
        }/oauth2/authorize?identity_provider=${localStorage.getItem(
          selectedIdentityProviderKey
        )}&client_id=${
          environmentVariables.cognito.clientId
        }&response_type=code&scope=email+openid+profile&redirect_uri=${encodeURIComponent(
          utils.getCurrentUrl()
        )}`;
      } else {
        setUser(getIdTokenResponseAndExpiration.user);
        setCredentialsProvider(
          await awsUtils.getCredentialsProvider(
            environmentVariables.region,
            environmentVariables.cognito.identityPoolId,
            environmentVariables.cognito.userPoolId,
            getIdTokenResponseAndExpiration.idToken
          )
        );
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  console.log(window.location);

  if (window.location.pathname === "/about") {
    return <About />;
  }

  return (
    <div className="App">
      <Header
        showEnterButton={false}
        showLogoutButton={
          isLoading === false && user !== null && credentialsProvider !== null
        }
      />
      {isLoading === true ? (
        <div className="h-full flex justify-center pt-[20em]">
          <BounceLoader className="absolute" size={100} />
        </div>
      ) : (
        <div className="App max-w-[40em] mx-auto select-none flex flex-col justify-center flex-1 mt-4">
          <>
            {environmentVariables !== null &&
              getCodeFromCurrentURL() === null &&
              credentialsProvider === null &&
              user === null && (
                <LoginScreen environmentVariables={environmentVariables} />
              )}
            {environmentVariables !== null &&
              user !== null &&
              credentialsProvider !== null && (
                <GlobalsContext.Provider
                  value={{
                    environmentVariables,
                    user,
                    credentialsProvider,
                  }}
                >
                  <Wrapper />
                </GlobalsContext.Provider>
              )}
          </>
        </div>
      )}
      <p className="text-gray-200 text-right p-4">{version}</p>
    </div>
  );
}

export default App;

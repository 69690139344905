export const EnterButton = () => {
  return (
    <button
      onClick={() => {
        window.location.href = "/";
      }}
      className="mr-4 my-4 rounded-full shadow-lg bg-gray-600 hover:bg-gray-700 text-white px-4 py-2"
    >
      Enter
      <i className={`ml-4 fa fa- fa-arrow-right`}></i>
    </button>
  );
};

type Props = {
  onClick: () => void;
};
export const GoogleLoginButton = ({ onClick }: Props) => (
  <button
    onClick={onClick}
    className="flex bg-[#eeeeee] hover:bg-[#dddddd] text-gray-500 font-bold py-2 px-4 rounded inline-flex items-center min-w-[17em]"
  >
    <img src="google-icon.svg" alt="" className="mr-4" />
    <p>Continue with Google</p>
  </button>
);

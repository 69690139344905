type Props = {
  onClick: () => void;
};
export const MicrosoftLoginButton = ({ onClick }: Props) => (
  <button
    onClick={onClick}
    className="flex bg-[#2f2f2f] hover:bg-black text-white font-bold py-2 px-4 rounded inline-flex items-center min-w-[17em]"
  >
    <img
      src="microsoft-icon.svg"
      alt=""
      className="ml-[2px] mr-[1.2em] w-[1.2em]"
    />
    <p>Continue with Microsoft</p>
  </button>
);

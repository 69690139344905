import React from "react";

type PlayButtonProps = {
  onClick: () => void;
  size: "small" | "big";
};

export const PlayButton: React.FC<PlayButtonProps> = ({ onClick, size }) => {
  return (
    <div className="block">
      <button
        className={`shadow-md bg-[#414344] hover:bg-gray-500 ${
          size === "big" ? "p-[1em]" : "p-[5px]"
        } rounded-full flex items-center justify-center`}
        onClick={onClick}
      >
        <svg
          className={`${
            size === "big" ? "w-10 h-10" : "w-5 h-5"
          } fill-white rounded-lg`} // Adjusted width, height, and added border-radius
          xmlns="http://www.w3.org/2000/svg"
          viewBox="2 2 20 20"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M8 5v14l11-7z" />
        </svg>
      </button>
    </div>
  );
};

import { useContext, useEffect, useState } from "react";
import { Song } from "../Library/Library";
import { GlobalsContext } from "../App";
import {
  S3Client,
  ListObjectsV2Command,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import {
  LambdaClient,
  InvokeCommand,
  InvokeCommandOutput,
} from "@aws-sdk/client-lambda";
import {
  DeleteItemCommand,
  DynamoDBClient,
  PutItemCommand,
  QueryCommand,
  ScanCommand,
} from "@aws-sdk/client-dynamodb";
import { marshall, unmarshall } from "@aws-sdk/util-dynamodb";
import { Config, PreCreatedConfig } from "../types";
import { ErrorWrapper } from "./utils";

const fetchItems = async (
  client: DynamoDBClient,
  tableName: string
): Promise<Song[]> => {
  const command = new ScanCommand({
    TableName: tableName,
  });

  const results = await client.send(command);
  const items =
    results
      .Items!.map((item) => unmarshall(item))
      .map(
        ({
          Id,
          Status,
          SmallestThumbnailUrl,
          Title,
          DurationInSeconds,
          RequestedBy,
          Lyrics,
        }) =>
          ({
            id: Id,
            status: Status,
            smallestThumbnailUrl: SmallestThumbnailUrl,
            title: Title,
            durationInSeconds: DurationInSeconds,
            requestedBy: RequestedBy,
            lyrics: Lyrics,
          } as Song)
      ) ?? [];
  return items;
};

const invokeAcceptSongLambda = async (
  lambdaClient: LambdaClient,
  functionName: string,
  youtubeId: string
) => {
  const payload = JSON.stringify({ youtubeId });
  const encoder = new TextEncoder();
  const encodedPayload = encoder.encode(payload);
  const command = new InvokeCommand({
    FunctionName: functionName,
    Payload: encodedPayload,
  });

  return lambdaClient.send(command);
};

export const fetchSongIds = async (
  s3Client: S3Client,
  bucketName: string
): Promise<string[]> => {
  const command = new ListObjectsV2Command({
    Bucket: bucketName,
    Delimiter: "/", // Delimiter for "directory"-like behavior
  });

  const data = await s3Client.send(command);
  return data.CommonPrefixes!.map(({ Prefix }) => Prefix!.slice(0, -1));
};

const generatePresignedUrl = async (
  s3Client: S3Client,
  bucketName: string,
  objectKey: string
) => {
  const getObjectParams = {
    Bucket: bucketName,
    Key: objectKey,
  };
  const command = new GetObjectCommand(getObjectParams);

  const signedUrl = await getSignedUrl(s3Client, command, {
    expiresIn: 3600,
  });
  return signedUrl;
};

const sortSongs = (songs: Song[]): Song[] => {
  const songsCopy = [...songs];
  return songsCopy.sort((a, b) => {
    const order: Record<Song["status"], number> = {
      Requested: 0,
      Queued: 1,
      Processing: 2,
      Failed: 3,
      Succeeded: 4,
    };

    return order[a.status] - order[b.status];
  });
};

export const useAwsClient = () => {
  const {
    user,
    credentialsProvider,
    environmentVariables: { region, bucketNames, lambdaArns, tableNames },
  } = useContext(GlobalsContext);

  const [s3Client, setS3Client] = useState<S3Client | null>(null);
  const [lambdaClient, setLambdaClient] = useState<LambdaClient | null>(null);
  const [dynamoDbClient, setDynamoDbClient] = useState<DynamoDBClient | null>(
    null
  );
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (s3Client !== null && lambdaClient !== null && dynamoDbClient !== null) {
      if (isReady === false) {
        setIsReady(true);
      }
    }
  }, [s3Client, lambdaClient, dynamoDbClient]);

  useEffect(() => {
    setS3Client(new S3Client({ region, credentials: credentialsProvider }));
    setLambdaClient(
      new LambdaClient({ region, credentials: credentialsProvider })
    );
    setDynamoDbClient(
      new DynamoDBClient({
        region,
        credentials: credentialsProvider,
      })
    );
  }, []);

  const getSongUrl = async (
    songId: string,
    withVocal: boolean,
    pitch: number
  ) => {
    const presignedUrl = await generatePresignedUrl(
      s3Client!,
      bucketNames.processed,
      `${songId}/${withVocal ? "with_vocals" : "no_vocals"}${
        pitch === 0 ? "" : `_${String(pitch)}`
      }.mp3`
    );
    return presignedUrl;
  };

  const requestSong = async (
    youtubeUrl: string
  ): Promise<{ id: string } | { error: string }> =>
    invoke<{ youtubeUrl: string }, { id: string }>(lambdaArns.requestSong, {
      youtubeUrl,
    });

  const acceptSong = async (youtubeId: string) => {
    if (!lambdaClient) {
      throw Error("Lambda client not initialized yet");
    }
    return invokeAcceptSongLambda(
      lambdaClient,
      lambdaArns.acceptSong,
      youtubeId
    );
  };

  const getSongs = async () => {
    if (!dynamoDbClient) {
      throw Error("DynamoDB client not initialized yet");
    }
    const songs = await fetchItems(dynamoDbClient, tableNames.songs);
    return sortSongs(songs);
  };

  const saveConfiguration = (configuration: PreCreatedConfig) => {
    const payload = JSON.stringify(configuration);
    const encoder = new TextEncoder();
    const encodedPayload = encoder.encode(payload);
    const command = new InvokeCommand({
      FunctionName: lambdaArns.saveConfiguration,
      Payload: encodedPayload,
    });

    return lambdaClient!.send(command);
  };

  const getSavedConfigurations = async (): Promise<Config[]> => {
    const queryParameters = {
      TableName: tableNames.configurations,
      KeyConditionExpression: "UserId = :userId",
      ExpressionAttributeValues: {
        ":userId": { S: user.id },
      },
    };
    const queryResult = await dynamoDbClient!.send(
      new QueryCommand(queryParameters)
    );
    return queryResult.Items!.map((item) => {
      const {
        Id,
        LoopFrom,
        LoopTo,
        Pitch,
        SongId,
        Speed,
        UserId,
        WithVocals,
        CreatedAt,
        Name,
      } = unmarshall(item);
      return {
        id: Id,
        name: Name,
        loopFrom: LoopFrom,
        loopTo: LoopTo,
        pitch: Pitch,
        songId: SongId,
        speed: Speed,
        userId: UserId,
        withVocals: WithVocals,
        createdAt: CreatedAt,
      };
    });
  };

  const deleteConfiguration = async ({ id }: Config): Promise<unknown> =>
    dynamoDbClient!.send(
      new DeleteItemCommand({
        TableName: tableNames.configurations,
        Key: marshall({ Id: id, UserId: user.id }),
      })
    );

  const removeFromFavorites = async (songId: string) => {
    return dynamoDbClient!.send(
      new DeleteItemCommand({
        TableName: tableNames.favorites,
        Key: marshall({
          UserId: user.id,
          SongId: songId,
        }),
      })
    );
  };

  const addToFavorites = async (songId: string) => {
    return dynamoDbClient!.send(
      new PutItemCommand({
        TableName: tableNames.favorites,
        Item: marshall({
          UserId: user.id,
          SongId: songId,
        }),
      })
    );
  };

  const getFavoriteSongIds = async (): Promise<string[]> => {
    const queryParameters = {
      TableName: tableNames.favorites,
      KeyConditionExpression: "UserId = :userId",
      ExpressionAttributeValues: {
        ":userId": { S: user.id },
      },
    };
    const queryResult = await dynamoDbClient!.send(
      new QueryCommand(queryParameters)
    );
    return queryResult.Items!.map((item) => {
      const { SongId } = unmarshall(item);
      return SongId;
    });
  };

  const deleteSong = async (songId: string): Promise<unknown> => {
    const payload = JSON.stringify({ id: songId });
    const encoder = new TextEncoder();
    const encodedPayload = encoder.encode(payload);
    const command = new InvokeCommand({
      FunctionName: lambdaArns.deleteSong,
      Payload: encodedPayload,
    });

    return lambdaClient!.send(command);
  };

  const invoke = async <PayloadType, ResponsePayloadType>(
    functionName: string,
    payload: PayloadType
  ): Promise<ResponsePayloadType> => {
    const encoder = new TextEncoder();
    const encodedPayload = encoder.encode(JSON.stringify(payload));
    const command = new InvokeCommand({
      FunctionName: functionName,
      Payload: encodedPayload,
    });

    const response = await lambdaClient!.send(command);
    const responsePayload = JSON.parse(
      new TextDecoder().decode(response.Payload)
    );

    return responsePayload as ResponsePayloadType;
  };

  const addLyricsToSong = async (
    songId: string,
    geniusUrl: string
  ): Promise<ErrorWrapper | null> =>
    invoke<{ songId: string; geniusUrl: string }, ErrorWrapper | null>(
      lambdaArns.addLyricsToSong,
      { songId, geniusUrl }
    );

  return {
    isReady,
    getSongUrl,
    getSongs,
    requestSong,
    acceptSong,
    saveConfiguration,
    getSavedConfigurations,
    deleteConfiguration,
    getFavoriteSongIds,
    addToFavorites,
    removeFromFavorites,
    deleteSong,
    addLyricsToSong,
  };
};

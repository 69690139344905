import Amplitude from "amplitudejs";
import { useEffect, useRef, useState } from "react";
import { Song } from "../Library/Library";
import { Configuration } from "../Wrapper/Wrapper";

type Props = {
  song: Song;
  configurations: Configuration[];
};

export const useAudioController = ({ song, configurations }: Props) => {
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [withVocals, setWithVocals] = useState<boolean>(true);
  const [pitch, setPitch] = useState<number>(0);
  const loopFromRef = useRef<number>(0);
  const loopToRef = useRef<number>(song.durationInSeconds - 1);

  const [loopFrom, setLoopFrom] = useState<number>(0);
  const [loopTo, setLoopTo] = useState<number>(song.durationInSeconds - 1);

  const [progress, setProgress] = useState<number>(0);

  const getIndexOf = (pitchToFind: number, withVocalsToFind: boolean) =>
    configurations.findIndex(
      ({ pitch, withVocals }) =>
        pitch === pitchToFind && withVocals === withVocalsToFind
    );

  const repeatCheckerInterval = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    loopFromRef.current = loopFrom;
  }, [loopFrom]);

  useEffect(() => {
    loopToRef.current = loopTo;
  }, [loopTo]);

  const jumpToSeconds = (seconds: number) => {
    let updated: number;
    if (seconds < loopFromRef.current) {
      updated = loopFromRef.current;
    } else if (seconds > loopToRef.current) {
      updated = loopToRef.current;
    } else {
      updated = seconds;
    }
    // For some reason it's not possible to set the percentage to 0, so
    // setting it to 0.01 instead
    Amplitude.setSongPlayedPercentage(
      Math.max((updated / Amplitude.getSongDuration()) * 100, 0.01)
    );
  };

  useEffect(() => {
    repeatCheckerInterval.current = setInterval(() => {
      let songPlayedSeconds = Amplitude.getSongPlayedSeconds();
      if (!Number.isNaN(songPlayedSeconds)) {
        if (songPlayedSeconds > loopToRef.current) {
          jumpToSeconds(loopFromRef.current);
        } else if (songPlayedSeconds < loopFromRef.current) {
          jumpToSeconds(loopFromRef.current);
        }
      }
    }, 1000);
    return () => {
      if (repeatCheckerInterval.current !== null) {
        clearInterval(repeatCheckerInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    (window as any).Amplitude = Amplitude;
    let updateTime: NodeJS.Timer;
    Amplitude.init({
      callbacks: {
        next: () => {},
        stop: () => {
          setIsPlaying(false);
        },
        play: () => {
          setIsPlaying(true);
        },
        pause: () => {
          setIsPlaying(false);
        },
        initialized: () => {
          setIsInitialized(true);
          updateTime = setInterval(() => {
            const currentSeconds = Amplitude.getSongPlayedSeconds();
            setProgress(Math.round(currentSeconds));
          }, 100);
        },
      },
      continue_next: false,
      songs: configurations.map(({ url }) => ({
        name: song.title,
        artist: song.id,
        url,
        visualization: "michaelbromley_visualization",

        cover_art_url:
          "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-to-answer.jpg",
      })),
      start_song: getIndexOf(pitch, withVocals),
      visualization: "michaelbromley_visualization",
      waveforms: {
        sample_rate: 50,
      },

      visualizations: [
        {
          object: (window as any).MichaelBromleyVisualization,
          params: {},
        },
      ],
    });

    return () => {
      clearTimeout(updateTime);
      Amplitude.stop();
    };
  }, []);

  useEffect(() => {
    if (isInitialized === true) {
      const currentSeconds = Amplitude.getSongPlayedSeconds();
      Amplitude.skipTo(currentSeconds, getIndexOf(pitch, withVocals), null);
    }
  }, [pitch, withVocals]);

  useEffect(() => {
    Amplitude.setPlaybackSpeed(playbackRate);
  }, [playbackRate]);

  return {
    jumpToSeconds,
    progress,
    setLoopFrom,
    setLoopTo,
    setPlaybackRate,
    isPlaying,
    isInitialized,
    loopFrom,
    loopTo,
    playbackRate,
    withVocals,
    onWithVocalsChange: (withVocals: boolean) => setWithVocals(withVocals),
    onPitchUp: () => setPitch((pitch) => Math.min(pitch + 1, 6)),
    onPitchDown: () => setPitch((pitch) => Math.max(pitch - 1, -6)),
    setPitch,
    pitch,
    play: () => Amplitude.play(),
    pause: () => Amplitude.pause(),
  };
};

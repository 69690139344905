import { GetIdTokenResponse } from "../App";

const prefix = "singipedia";
const getIdTokenResponseAndExpirationPostfix =
  "getIdTokenResponseAndExpiration";

type GetIdTokenResponseAndExpiration = GetIdTokenResponse & {
  expiration: number;
};

const saveIdTokenResponseAndExpiration = (
  getIdTokenResponseAndExpiration: GetIdTokenResponseAndExpiration
) => {
  localStorage.setItem(
    `${prefix}:${getIdTokenResponseAndExpirationPostfix}`,
    JSON.stringify(getIdTokenResponseAndExpiration)
  );
};

const getIdTokenResponseAndExpiration =
  (): GetIdTokenResponseAndExpiration | null => {
    const idTokenAndExpirationString = localStorage.getItem(
      `${prefix}:${getIdTokenResponseAndExpirationPostfix}`
    );
    if (idTokenAndExpirationString === null) {
      return null;
    }
    return JSON.parse(
      idTokenAndExpirationString
    ) as GetIdTokenResponseAndExpiration;
  };

export const localStorageUtils = {
  saveIdTokenResponseAndExpiration,
  getIdTokenResponseAndExpiration,
};

import { useEffect, useState } from "react";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import { Library, Song } from "../Library/Library";
import { useAwsClient } from "../utils/useAwsClient";
import _ from "lodash";
import { Config } from "../types";
import { utils } from "../utils/utils";

const numberOfPitchAdjustments = 6;

export type Configuration = {
  url: string;
  pitch: number;
  withVocals: boolean;
};

export const Wrapper: React.FC = () => {
  const [songs, setSongs] = useState<Song[] | null>(null);
  const [currentSong, setCurrentSong] = useState<Song | null>(null);
  const [currentSongConfigurations, setCurrentSongConfigurations] = useState<
    Configuration[] | null
  >(null);
  const [savedConfigurations, setSavedConfigurations] = useState<
    Config[] | null
  >(null);
  const [favoriteSongIds, setFavoriteSongIds] = useState<string[] | null>(null);
  const {
    isReady,
    getSongs,
    getSongUrl,
    requestSong,
    acceptSong,
    saveConfiguration,
    getSavedConfigurations,
    deleteConfiguration,
    addToFavorites,
    getFavoriteSongIds,
    removeFromFavorites,
    deleteSong,
    addLyricsToSong,
  } = useAwsClient();

  const fetchSavedConfigurations = async () => {
    const savedConfigurations = await getSavedConfigurations();
    setSavedConfigurations(savedConfigurations);
  };

  useEffect(() => {
    if (isReady === true) {
      getFavoriteSongIds().then((favorites) => setFavoriteSongIds(favorites));

      getSavedConfigurations().then(setSavedConfigurations);
      getSongs().then((songs) => setSongs(songs));

      setInterval(() => {
        getSongs().then((songs) => setSongs(songs));
      }, 30000);
    }
  }, [isReady]);

  const selectSong = async (songId: string) => {
    const configurations = await Promise.all(
      _.range(-numberOfPitchAdjustments, numberOfPitchAdjustments + 1)
        .flatMap((pitch) =>
          [true, false].map((withVocals) => ({
            pitch,
            withVocals,
          }))
        )
        .map(async ({ pitch, withVocals }) => {
          const url = await getSongUrl(songId, withVocals, pitch);
          return { url, pitch, withVocals };
        })
    );

    setCurrentSongConfigurations(configurations);
    setCurrentSong(null);
    setTimeout(() => {
      setCurrentSong(songs!.find((song) => song.id === songId)!);
    }, 1);
  };

  const addToFavoritesAndRefresh = async (songId: string) => {
    await addToFavorites(songId);
    const favorites = await getFavoriteSongIds();
    setFavoriteSongIds(favorites);
  };

  return (
    <>
      {currentSong !== null &&
        currentSongConfigurations !== null &&
        savedConfigurations !== null &&
        favoriteSongIds !== null && (
          <AudioPlayer
            refetchSong={async () => {
              const songs = await getSongs();
              setSongs(songs);
              const newCurrentSong = songs.find(
                (song) => song.id === currentSong.id
              );
              if (newCurrentSong !== undefined) {
                setCurrentSong(newCurrentSong);
              }
            }}
            onDeleteConfigurationClick={async (configuration) => {
              await deleteConfiguration(configuration);
              await fetchSavedConfigurations();
            }}
            savedConfigurations={savedConfigurations.filter(
              ({ songId }) => songId === currentSong.id
            )}
            song={currentSong}
            addLyricsToSong={addLyricsToSong}
            configurations={currentSongConfigurations}
            onSaveConfiguration={async (config) => {
              await saveConfiguration(config);

              const savedConfigurations = await getSavedConfigurations();
              setSavedConfigurations(savedConfigurations);
            }}
            addSongToFavorites={() => addToFavoritesAndRefresh(currentSong.id)}
            removeSongFromFavorites={async () => {
              await removeFromFavorites(currentSong.id);
              const favorites = await getFavoriteSongIds();
              setFavoriteSongIds(favorites);
            }}
            isFavorite={favoriteSongIds.includes(currentSong.id)}
            deleteSong={deleteSong}
          />
        )}
      {songs !== null && favoriteSongIds !== null && (
        <Library
          currentSongId={currentSong?.id || null}
          requestSong={async (youtubeUrl) => {
            const requestSongResponse = await requestSong(youtubeUrl);
            if (!utils.isErrorWrapper(requestSongResponse)) {
              const [songs] = await Promise.all([
                getSongs(),
                addToFavoritesAndRefresh(requestSongResponse.id),
              ]);
              setSongs(songs);
            }
            return requestSongResponse;
          }}
          acceptSong={async (youtubeId) => {
            await acceptSong(youtubeId);
            getSongs().then((songs) => setSongs(songs));
          }}
          songs={songs}
          favoriteSongIds={favoriteSongIds}
          onSelectSong={selectSong}
        />
      )}
    </>
  );
};

const isPhone = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /android|iphone|ipad|ipod|windows phone/i.test(
    userAgent.toLowerCase()
  );
};

const secondsToMinutesAndSeconds = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${paddedMinutes}:${paddedSeconds}`;
};

const getCurrentUrl = () => window.location.origin;

export type ErrorWrapper = {
  error: string;
};

const isErrorWrapper = (
  errorWrapper: ErrorWrapper | unknown
): errorWrapper is ErrorWrapper => {
  return (errorWrapper as ErrorWrapper)?.error !== undefined;
};

export const utils = {
  isErrorWrapper,
  isPhone,
  secondsToMinutesAndSeconds,
  getCurrentUrl,
};

import _ from "lodash";
import { Config } from "../types";
import { FaButton } from "../AudioPlayer/FaButton";
import Swal from "sweetalert2";

type Props = {
  configurations: Config[];
  onConfigurationSelect: (configuration: Config) => unknown;
  onDeleteConfigurationClick: (configuration: Config) => unknown;
  activeConfigId: string | undefined;
  saveConfig: () => unknown;
};
export const SavedConfigurations = ({
  configurations,
  onConfigurationSelect,
  onDeleteConfigurationClick,
  saveConfig,
  activeConfigId,
}: Props) => {
  return (
    <div className="mt-2">
      <h3 className="now-playing pt-2 text-left pl-[2px]">
        Saved song configurations:
      </h3>
      {configurations.length === 0 && (
        <p className="text-gray-500 text-left my-2">
          You haven't saved any song configurations.
        </p>
      )}
      {configurations.length > 0 && (
        <ul className="border-b">
          {_.reverse(
            _.sortBy(configurations, ({ createdAt }) => createdAt)
          ).map((configuration) => (
            <li
              key={configuration.id}
              className={`flex border-t w-full ${
                activeConfigId === configuration.id ? "bg-gray-200" : ""
              }`}
            >
              <div
                onClick={() => onConfigurationSelect(configuration)}
                className={`${
                  activeConfigId === configuration.id
                    ? ""
                    : "cursor-pointer hover:text-gray-500 text-blue-800"
                } text-[#414344] h-full my-auto pl-4 flex-1 flex-row flex w-full text-left w-full`}
              >
                <p>{configuration.name}</p>
              </div>
              <FaButton
                size="small"
                onClick={async () => {
                  const { isConfirmed } = await Swal.fire({
                    title: "Delete saved configuration",
                    text: `Are you sure you want to delete the saved configuration '${configuration.name}'? You won't be able to revert this!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    reverseButtons: true,
                  });
                  if (isConfirmed) {
                    onDeleteConfigurationClick(configuration);
                  }
                }}
                icon="trash"
              />
            </li>
          ))}
        </ul>
      )}
      {activeConfigId !== undefined ? (
        <p className="text-left text-gray-400">
          Current configuration is saved
        </p>
      ) : (
        <button
          onClick={() => saveConfig()}
          className="text-blue-800 w-full text-left"
        >
          + Save current configuration
        </button>
      )}
    </div>
  );
};

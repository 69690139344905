import React, { useContext } from "react";
import Swal from "sweetalert2";
import "rc-slider/assets/index.css";
import { Song } from "../Library/Library";
import { Configuration } from "../Wrapper/Wrapper";
import { Slider, Switch } from "@mui/material";
import { PlusMinus } from "./PlusMinus";
import { FaButton } from "./FaButton";
import { useAudioController } from "./useAudioController";
import { Config, PreCreatedConfig } from "../types";
import { ErrorWrapper, utils } from "../utils/utils";
import { SavedConfigurations } from "../SavedConfigurations/SavedConfigurations";
import _ from "lodash";
import { GlobalsContext } from "../App";

type Props = {
  song: Song;
  configurations: Configuration[];
  onSaveConfiguration: (config: PreCreatedConfig) => Promise<unknown>;
  savedConfigurations: Config[];
  onDeleteConfigurationClick: (configuration: Config) => unknown;
  addSongToFavorites: () => unknown;
  removeSongFromFavorites: () => unknown;
  isFavorite: boolean;
  deleteSong: (songId: string) => Promise<unknown>;
  addLyricsToSong: (
    songId: string,
    geniusUrl: string
  ) => Promise<ErrorWrapper | null>;
  refetchSong: () => unknown;
};

const AudioPlayer: React.FC<Props> = ({
  song,
  configurations,
  onSaveConfiguration,
  savedConfigurations,
  onDeleteConfigurationClick,
  addSongToFavorites,
  removeSongFromFavorites,
  isFavorite,
  deleteSong,
  addLyricsToSong,
  refetchSong,
}) => {
  const { user } = useContext(GlobalsContext);
  const {
    jumpToSeconds,
    progress,
    setLoopFrom,
    setLoopTo,
    setPlaybackRate,
    isPlaying,
    isInitialized,
    loopFrom,
    loopTo,
    playbackRate,
    onWithVocalsChange,
    withVocals,
    pitch,
    onPitchDown,
    onPitchUp,
    play,
    pause,
    setPitch,
  } = useAudioController({ song, configurations });

  const setConfig = (config: Config) => {
    setLoopFrom(config.loopFrom);
    setLoopTo(config.loopTo);
    setPlaybackRate(config.speed);
    setPitch(config.pitch);
    onWithVocalsChange(config.withVocals);
  };

  const activeConfigId = () =>
    savedConfigurations.find(
      ({ id, createdAt, songId, userId, name, ...configuration }) =>
        _.isEqual(configuration, {
          loopFrom,
          loopTo,
          pitch,
          speed: playbackRate,
          withVocals,
        })
    )?.id;

  if (isInitialized === false) {
    return null;
  }

  const addLyricsButton = (
    <button
      onClick={async () => {
        const { isConfirmed, value } = await Swal.fire({
          heightAuto: false,
          title: "Add genius lyrics",
          text: "Here you can add the genius link to the lyrics. Find the page on genius which shows the lyrics for the song and paste the url here",
          reverseButtons: true,
          input: "text",
          showCancelButton: true,
          confirmButtonText: "Add",
          inputPlaceholder: "Ex: https://genius.com/Ac-dc-back-in-black-lyrics",
        });
        if (isConfirmed === true && value !== undefined) {
          Swal.fire({
            title: "Adding lyrics",
            text: "Please wait...",
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          const addLyricsToSongResponse = await addLyricsToSong(song.id, value);
          Swal.close();

          if (addLyricsToSongResponse?.error) {
            return Swal.fire({
              icon: "error",
              title: "Oops... We couldn't add the lyrics to the song",
              text: addLyricsToSongResponse.error,
            });
          } else {
            refetchSong();
          }
        }
      }}
      className="text-[#414344] hover:text-gray-500 p-2 w-[9em] absolute right-0 text-blue-800"
    >
      + Add lyrics
    </button>
  );

  return (
    <div className="sm:px-[2em]">
      <div className="white-card mt-8 p-2 sm:rounded">
        {user.isAdmin && (
          <div>
            <button
              onClick={async () => {
                const { isConfirmed } = await Swal.fire({
                  title: "Are you sure you want to delete the song?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes, delete it!",
                  reverseButtons: true,
                });
                if (isConfirmed) {
                  await deleteSong(song.id);
                  window.location.reload();
                }
              }}
            >
              Delete song
            </button>
            {addLyricsButton}
          </div>
        )}
        <FaButton
          icon="heart red"
          className={
            isFavorite
              ? "text-red-500 sm:hover:text-red-500"
              : "sm:hover:text-red-300"
          }
          onClick={() =>
            isFavorite ? removeSongFromFavorites() : addSongToFavorites()
          }
        />

        {_.isNil(song.lyrics) ? (
          addLyricsButton
        ) : (
          <button
            onClick={async () => {
              if (song.lyrics) {
                await Swal.fire({
                  heightAuto: false,
                  title: "Lyrics",
                  showCloseButton: true,
                  confirmButtonText: "Close",
                  html: `
                  <div class="text-left whitespace-pre-line">${song.lyrics}</div>
                `,
                });
              }
            }}
            className="text-[#414344] hover:text-gray-500 p-2 w-[9em] absolute right-0 text-blue-800"
          >
            Show lyrics
          </button>
        )}
        <div className="pt-4 font-semibold text-[#414344]">Now Playing</div>
        <h2 className="mb-8 text-xl text-[#414344]">{song.title}</h2>

        <div id="white-player-center">
          <div className="items-center flex flex-row space-x-1 justify-center">
            <div className="flex flex-row flex-1 justify-end">
              <FaButton
                onClick={() => jumpToSeconds(loopFrom)}
                icon="backward"
              />
              <FaButton
                onClick={() => jumpToSeconds(progress - 5)}
                icon="step-backward"
              />
            </div>

            {isPlaying ? (
              <FaButton size="4x" icon="pause-circle" onClick={() => pause()} />
            ) : (
              <FaButton size="4x" icon="play-circle" onClick={() => play()} />
            )}
            <div className="flex flex-row flex-1 justify-start">
              <FaButton
                onClick={() => jumpToSeconds(progress + 5)}
                icon="step-forward"
              />
            </div>
          </div>

          <div className="px-8 flex flex-col mt-4">
            <Slider
              valueLabelDisplay="auto"
              className="w-full"
              sx={{
                touchAction: "none", // Add this line to disable touch scrolling
                "& .MuiSlider-markLabel": {
                  color: "#999",
                },
                "& .MuiSlider-mark": {
                  backgroundColor: "#999",
                  width: "2px",
                  height: "2em",
                  zIndex: 5,
                },
              }}
              marks={[
                {
                  value: Math.round(progress),
                  label: utils.secondsToMinutesAndSeconds(Math.round(progress)),
                },
              ]}
              valueLabelFormat={(value) =>
                `${Math.floor(value / 60)}:${String(value % 60).padStart(
                  2,
                  "0"
                )}`
              }
              onChange={(e, value) => {
                setLoopFrom((value as number[])[0]);
                setLoopTo((value as number[])[1]);
              }}
              value={[loopFrom, loopTo]}
              step={1}
              min={0}
              max={song.durationInSeconds}
            />
          </div>
        </div>

        <div className="flex flex-row pb-4 items-center">
          <table className="flex-1 w-full table-fixed leading-none">
            <tbody className="text-left">
              <tr className="w-[7em] border-b">
                <td className="w-1/4 py-[.5em]">
                  <span className="leading-none">Loop from:</span>
                </td>
                <td className="w-1/4">
                  {utils.secondsToMinutesAndSeconds(loopFrom)}
                </td>
                <td className="w-2/4 py-[.5em]">
                  <PlusMinus
                    repeatOnHolding
                    onPlus={() =>
                      setLoopFrom((repeatFrom) =>
                        Math.min(repeatFrom + 1, loopTo)
                      )
                    }
                    onMinus={() =>
                      setLoopFrom((repeatFrom) => Math.max(repeatFrom - 1, 0))
                    }
                  />
                </td>
              </tr>
              <tr className="border-b py-[2px]">
                <td className="py-[.5em]">
                  <span className="leading-none">Loop to:</span>
                </td>
                <td>{utils.secondsToMinutesAndSeconds(loopTo)}</td>
                <td className="py-[.5em]">
                  <PlusMinus
                    repeatOnHolding
                    onPlus={() =>
                      setLoopTo((repeatTo) =>
                        Math.min(repeatTo + 1, song.durationInSeconds)
                      )
                    }
                    onMinus={() =>
                      setLoopTo((repeatTo) => Math.max(repeatTo - 1, loopFrom))
                    }
                  />
                </td>
              </tr>
              <tr className="border-b py-[2px]">
                <td className="py-[.5em]">
                  <span className="leading-none">Speed:</span>
                </td>
                <td>{Math.round(playbackRate * 100)}% of original</td>
                <td className="py-[.5em]">
                  <PlusMinus
                    onPlus={() =>
                      setPlaybackRate((oldPlaybackRate) =>
                        Math.min(oldPlaybackRate + 0.05, 1.5)
                      )
                    }
                    onMinus={() =>
                      setPlaybackRate((oldPlaybackRate) =>
                        Math.max(oldPlaybackRate - 0.05, 0.5)
                      )
                    }
                  />
                </td>
              </tr>
              <tr className="text-left w-[7em] border-b py-[2px]">
                <td>
                  <span className="leading-none">Pitch:</span>
                </td>
                <td>{pitch === 0 ? "Original" : `${pitch} half steps`}</td>
                <td className="w-[10em] py-[.5em]">
                  <PlusMinus
                    onPlus={() => onPitchUp()}
                    onMinus={() => onPitchDown()}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">Vocals:</td>
                <td className="flex flex-row">
                  <Switch
                    defaultChecked={withVocals}
                    onChange={(e, checked) => onWithVocalsChange(checked)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <SavedConfigurations
            onDeleteConfigurationClick={onDeleteConfigurationClick}
            onConfigurationSelect={setConfig}
            configurations={savedConfigurations}
            activeConfigId={activeConfigId()}
            saveConfig={
              activeConfigId()
                ? () => ({})
                : async () => {
                    const { isConfirmed, value } = await Swal.fire({
                      heightAuto: false,
                      title: "Give the configuration a suitable name",
                      reverseButtons: true,
                      input: "text",
                      showCancelButton: true,
                      confirmButtonText: "Add configuration",
                      inputPlaceholder: "Add a suitable name",
                    });
                    if (isConfirmed === true && value !== undefined) {
                      onSaveConfiguration({
                        songId: song.id,
                        name: value,
                        pitch,
                        withVocals,
                        loopFrom,
                        loopTo,
                        speed: playbackRate,
                      });
                    }
                  }
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;

import { GoogleLoginButton } from "./GoogleLoginButton";
import { EnvironmentVariables } from "./App";
import {
  serviceName,
  selectedIdentityProviderKey,
  IdentityProvider,
} from "./constants";
import { utils } from "./utils/utils";
import { MicrosoftLoginButton } from "./MicrosoftLoginButton";

type Props = {
  environmentVariables: EnvironmentVariables;
};

export const LoginScreen: React.FC<Props> = ({ environmentVariables }) => {
  return (
    <div>
      <div className="white-card mb-4 sm:rounded-lg">
        <div className="p-4">
          <h3 className="mb-4 py-2 text-2xl">
            <strong>Welcome to {serviceName}</strong>
          </h3>
          <p>
            As a passionate singer and developer, I'm excited to introduce our
            platform for enhanced singing practice. With the use of AI, we can
            extract vocals from YouTube videos, allowing you to sing along with
            the original music of the songs you love. You can request any
            YouTube song if the song you want to practice to is not already
            here. For questions or feedback, don't hesitate to contact us at
            hello@singipedia.net. Enjoy practicing with us! 🎤🎶
          </p>
        </div>
        <div className="flex flex-col border-t p-4 sm:rounded-b-lg">
          <div className="items-center pb-[1em] flex flex-col">
            <p className="text-left text-gray-800 min-w-[17em]">Sign in:</p>
            <MicrosoftLoginButton
              onClick={() => {
                localStorage.setItem(
                  selectedIdentityProviderKey,
                  JSON.stringify(IdentityProvider.Microsoft)
                );
                window.location.href = `${
                  environmentVariables.cognito.domain
                }/oauth2/authorize?identity_provider=${
                  IdentityProvider.Microsoft
                }&client_id=${
                  environmentVariables.cognito.clientId
                }&response_type=code&scope=email+openid+profile&redirect_uri=${encodeURIComponent(
                  utils.getCurrentUrl()
                )}`;
              }}
            />
            <div className="h-[.2em]" />
            <GoogleLoginButton
              onClick={() => {
                localStorage.setItem(
                  selectedIdentityProviderKey,
                  JSON.stringify(IdentityProvider.Google)
                );
                window.location.href = `${
                  environmentVariables.cognito.domain
                }/oauth2/authorize?identity_provider=${
                  IdentityProvider.Google
                }&client_id=${
                  environmentVariables.cognito.clientId
                }&response_type=code&scope=email+openid+profile&redirect_uri=${encodeURIComponent(
                  utils.getCurrentUrl()
                )}`;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { FaButton } from "./AudioPlayer/FaButton";
import { EnterButton } from "./EnterButton";
import { websiteDomain } from "./constants";

export const Header = ({
  showLogoutButton,
  showEnterButton,
}: {
  showLogoutButton: boolean;
  showEnterButton: boolean;
}) => {
  return (
    <div
      id="header"
      className="flex flex-row w-full bg-white shadow z-10 sm:justify-center items-center"
    >
      <button
        className="flex flex-row items-center"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <img className="w-[4em]" src="/singing-fun.png" alt="singipedia" />
        <h3 className="text-gray-700">{websiteDomain}</h3>
      </button>
      {showLogoutButton && (
        <FaButton
          className="absolute right-0"
          size="lg"
          icon="sign-out"
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        />
      )}
      {showEnterButton && (
        <div className="absolute right-0">
          <EnterButton />
        </div>
      )}
    </div>
  );
};

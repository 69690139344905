import { useRef } from "react";
import { utils } from "../utils/utils";

type Props = {
  icon: string;
  onClick: () => void;
  size: string;
  repeatOnHolding: boolean;
  className: string;
  disabled: boolean;
};

const defaultTimeoutTime = 500;
const minTimeoutTime = 30;
const timeDelta = 0.6;

export const FaButton = ({
  icon,
  onClick,
  size,
  repeatOnHolding,
  disabled,
  className,
}: Props) => {
  const onHoldingDownRef = useRef<NodeJS.Timer | null>(null);
  const timeoutTime = useRef<number>(defaultTimeoutTime);

  const handleTouchStart = (fn: () => void) => () => {
    fn();
    if (repeatOnHolding === true) {
      timeoutTime.current = Math.max(
        timeoutTime.current * timeDelta,
        minTimeoutTime
      );
      onHoldingDownRef.current = setTimeout(
        handleTouchStart(fn),
        timeoutTime.current
      );
    }
  };

  const handleTouchEnd = () => {
    if (onHoldingDownRef.current !== null) {
      clearInterval(onHoldingDownRef.current);
    }
    onHoldingDownRef.current = null;
    timeoutTime.current = defaultTimeoutTime;
  };
  return (
    <button
      disabled={disabled}
      className={`p-2 sm:hover:text-gray-500 text-[#414344] ${className}`}
      onTouchStart={
        utils.isPhone() && repeatOnHolding === true
          ? handleTouchStart(onClick)
          : undefined
      }
      onMouseDown={
        utils.isPhone() || repeatOnHolding === false
          ? undefined
          : handleTouchStart(onClick)
      }
      onMouseUp={
        utils.isPhone() || repeatOnHolding === false
          ? undefined
          : handleTouchEnd
      }
      onMouseOut={
        utils.isPhone() || repeatOnHolding === false
          ? undefined
          : handleTouchEnd
      }
      onTouchEnd={
        utils.isPhone() && repeatOnHolding === true ? handleTouchEnd : undefined
      }
      onClick={repeatOnHolding === true ? undefined : onClick}
    >
      <i className={`fa fa-${icon} fa-${size}`}></i>
    </button>
  );
};

FaButton.defaultProps = {
  size: "2xl",
  repeatOnHolding: false,
  className: "",
  disabled: false,
};

import {
  CognitoIdentityClient,
  GetIdCommand,
} from "@aws-sdk/client-cognito-identity";
import {
  fromCognitoIdentity,
  CognitoIdentityCredentialProvider,
} from "@aws-sdk/credential-provider-cognito-identity";

const getCredentialsProvider = async (
  region: string,
  identityPoolId: string,
  userPoolId: string,
  identityToken: string
): Promise<CognitoIdentityCredentialProvider> => {
  const cognitoIdentityClient = new CognitoIdentityClient({ region });

  const getIdCommand = new GetIdCommand({
    IdentityPoolId: identityPoolId,
    Logins: {
      [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: identityToken,
    },
  });
  const identityId = await cognitoIdentityClient.send(getIdCommand);

  const credentialsProvider = fromCognitoIdentity({
    client: cognitoIdentityClient,
    logins: {
      [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: identityToken,
    },
    identityId: identityId.IdentityId!,
  });

  return credentialsProvider;
};

export const awsUtils = {
  getCredentialsProvider,
};
